
import Vue from "vue";
import {validationMixin} from 'vuelidate'
import {
  minLength,
  required, sameAs
} from 'vuelidate/lib/validators'
import {Backend} from "api-frontend";
import {authentication} from "mp-common/src/types/api/authenticationApi";
import {containsLowercase, containsNumber, containsSpecial, containsUppercase} from "@/extensions/custom-validations";

export default Vue.component("ChangePassword", {
  name: 'ChangePassword',
  mixins: [validationMixin],
  data() {
    return {
      form: {
        presentPassword: "",
        newPassword: "",
        confirmPassword: ""
      },
      sending: false,
      invalidCredentials: false
    }
  },
  props: {
    user: {},
    backend: {
      type: Backend,
    }
  },
  validations: {
    form: {
      presentPassword: {
        required
      },
      newPassword: {
        required,
        minLength: minLength(8),
        containsNumber,
        containsUppercase,
        containsLowercase,
        containsSpecial
      },
      confirmPassword: {
        required,
        sameAs: sameAs('newPassword'),
      }
    }
  },
  methods: {
    getValidationClass(fieldName: string) {
      const field = this.$v.form[fieldName]

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },
    clearForm() {
      this.$v.$reset()
      this.form.presentPassword = "";
      this.form.newPassword = "";
      this.form.confirmPassword = "";
    },
    async changePassword() {
      this.sending = true
      const result = await this.backend.query(authentication.changePassword, {currentPassword: this.form.presentPassword, newPassword: this.form.newPassword})
      if (result) {
        this.sending = false;
        this.$emit("changed");
      } else {
        this.sending = false;
        this.invalidCredentials = true;
      }
    },
    validateChangePassword() {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.changePassword()
      }
    },
  },
  template: `
    <form novalidate @submit.prevent="validateChangePassword">
    <md-card class="md-layout-item md-size-50 md-small-size-100" style="max-width: 350px; min-width: 0; width: 100vw">
      <md-card-header>
        <div class="md-title">Passwort ändern für {{ user.username }}</div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-small-size-100">
            <md-field :class="getValidationClass('presentPassword')">
              <label for="currentPassword">Aktuelles Passwort</label>
              <md-input name="currentPassword" id="currentPassword" v-model="form.presentPassword"
                        :disabled="sending" type="password"/>
              <span class="md-error" v-if="!$v.form.presentPassword.required">Das aktuelle Passwort ist notwendig</span>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100">
            <md-field :class="getValidationClass('newPassword')">
              <label for="newPassword">Neues Passwort</label>
              <md-input name="newPassword" id="newPassword" v-model="form.newPassword"
                        :disabled="sending" type="password"/>
              <span class="md-error" v-if="!$v.form.newPassword.required">Das neue Passwort ist notwendig</span>
              <span class="md-error"
                    v-if="!$v.form.newPassword.minLength && $v.form.newPassword.required">Das Passwort muss aus mindestens 8 Zeichen bestehen</span>
              <span class="md-error"
                    v-if="!$v.form.newPassword.containsNumber && $v.form.newPassword.required && $v.form.newPassword.minLength">Das Passwort muss eine Zahl enthalten</span>
              <span class="md-error" v-if="!$v.form.newPassword.containsUppercase && $v.form.newPassword.required && $v.form.newPassword.minLength && $v.form.newPassword.containsNumber">Das Passwort muss einen Großbuchstaben enthalten</span>
              <span class="md-error" v-if="!$v.form.newPassword.containsLowercase && $v.form.newPassword.required && $v.form.newPassword.minLength && $v.form.newPassword.containsNumber && $v.form.newPassword.containsUppercase">Das Passwort muss einen Kleinbuchstaben enthalten</span>
              <span class="md-error" v-if="!$v.form.newPassword.containsSpecial && $v.form.newPassword.required && $v.form.newPassword.minLength && $v.form.newPassword.containsNumber && $v.form.newPassword.containsUppercase && $v.form.newPassword.containsLowercase">Das Passwort muss ein Sonderzeichen enthalten</span>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100">
            <md-field :class="getValidationClass('confirmPassword')">
              <label for="confirmPassword">Passwort bestätigen</label>
              <md-input name="confirmPassword" id="confirmPassword" v-model="form.confirmPassword"
                        :disabled="sending" type="password"/>
              <span class="md-error" v-if="!$v.form.confirmPassword.required">The confirmation is required</span>
              <span class="md-error" v-if="!$v.form.confirmPassword.sameAs && $v.form.confirmPassword.required">Die Passwörter müssen übereinstimmen</span>
            </md-field>
          </div>
        </div>
      </md-card-content>

      <md-progress-bar md-mode="indeterminate" v-if="sending"/>

      <md-card-actions>
        <md-button v-on:click="$emit('cancel')" :disabled="sending">Abbrechen</md-button>
        <md-button type="submit" class="md-primary" :disabled="sending">Ändern</md-button>
      </md-card-actions>
    </md-card>

    <md-snackbar :md-active.sync="invalidCredentials" style="background-color: #FE2E2E"><span
        style="text-align: center; width: 100%">Access denied</span></md-snackbar>
    </form>
  `
})
