export const containsNumber = (value: string): boolean => {
    return value.match(/[0-9]/) !== null
}
export const containsUppercase = (value: string): boolean => {
    return value.match(/[A-ZÖÄÜ]/) !== null
}
export const containsLowercase = (value: string): boolean => {
    return value.match(/[a-zöäüß]/) !== null
}
export const containsSpecial = (value: string): boolean => {
    const match = value.match(/[0-9a-zA-ZöäüÖÄÜß]/g);
    return (match === null && value.length > 0) || (match !== null && match.length < value.length);
}